import { useColorModeValue } from '@chakra-ui/react';

export default function useColors() {
  const bgColor = useColorModeValue('#1a1a1a14', '#ffffff14');
  const color = useColorModeValue('#1a1a1a99', '#8F959E');
  const activeColor = useColorModeValue('#1a1a1a', '#ffffff');
  return {
    text: {
      default: color,
      active: activeColor,
      hover: 'link_hovered',
    },
    bg: {
      default: 'transparent',
      active: bgColor,
    },
    border: {
      default: 'divider',
      active: useColorModeValue('=.50', 'link'),
    },
  };
}

export const BODY_TYPEFACE = `"Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`;
export const HEADING_TYPEFACE = `"Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`;

const typography = {
  fonts: {
    heading: `${HEADING_TYPEFACE}`,
    body: `${BODY_TYPEFACE}`,
  },
  textStyles: {
    h2: {
      fontSize: ['32px'],
      fontWeight: '500',
      lineHeight: '40px',
    },
    h3: {
      fontSize: '24px',
      fontWeight: '500',
      lineHeight: '32px',
    },
  },
};

export default typography;

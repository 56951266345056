import {
  Text,
  HStack,
  Box,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';

import type { NavGroupItem } from 'types/client/navigation-items';

import IconSvg from 'ui/shared/IconSvg';

import NavLink from './NavLink';
import NavLinkIcon from './NavLinkIcon';
import useNavLinkStyleProps from './useNavLinkStyleProps';

type Props = {
  item: NavGroupItem;
  isCollapsed?: boolean;
};

const NavLinkGroupDesktop = ({ item, isCollapsed }: Props) => {
  const isExpanded = isCollapsed === false;
  const menuBgColor = useColorModeValue('#ffffff', '#1a1a1a');

  const styleProps = useNavLinkStyleProps({
    isCollapsed,
    isExpanded,
    isActive: item.isActive,
  });

  return (
    <Box as="li" listStyleType="none" w="100%">
      <Popover trigger="hover" placement="right-start" isLazy>
        <PopoverTrigger>
          <Link
            {...styleProps.itemProps}
            w={{
              lg: isExpanded ? '224px' : '48px',
              xl: isCollapsed ? '48px' : '224px',
            }}
            py="14px"
            px="10px"
            aria-label={`${item.text} link group`}
            position="relative"
            justifyContent={isCollapsed ? 'center' : 'unset'}
          >
            <HStack spacing={3} overflow="hidden">
              <NavLinkIcon item={item} />
              <Text
                {...styleProps.textProps}
                display={isCollapsed ? 'none' : 'block'}
              >
                {item.text}
              </Text>
              <IconSvg
                name="arrows/east-mini"
                position="absolute"
                right="7px"
                transform="rotate(180deg)"
                boxSize={6}
                opacity={{
                  lg: isExpanded ? '1' : '0',
                  xl: isCollapsed ? '0' : '1',
                }}
                transitionProperty="opacity"
                transitionDuration="normal"
                transitionTimingFunction="ease"
              />
            </HStack>
          </Link>
        </PopoverTrigger>
        <PopoverContent
          borderRadius="8px"
          backgroundColor={menuBgColor}
          width="284px"
          top={{ lg: isExpanded ? '-16px' : 0, xl: isCollapsed ? 0 : '-16px' }}
        >
          <PopoverBody p="16px">
            <Text
              variant="secondary"
              mb={2}
              display={{
                lg: isExpanded ? 'none' : 'block',
                xl: isCollapsed ? 'block' : 'none',
              }}
            >
              {item.text}
            </Text>
            <VStack spacing={4} alignItems="start">
              {item.subItems.map((subItem, index) =>
                Array.isArray(subItem) ? (
                  <Box
                    key={index}
                    w="100%"
                    as="ul"
                    _notLast={{
                      mb: 2,
                      pb: 2,
                      borderBottomWidth: '1px',
                      borderColor: 'divider',
                    }}
                  >
                    {subItem.map((subSubItem) => (
                      <NavLink
                        key={subSubItem.text}
                        item={subSubItem}
                        isCollapsed={false}
                      />
                    ))}
                  </Box>
                ) : (
                  <NavLink
                    key={subItem.text}
                    item={subItem}
                    isCollapsed={false}
                  />
                )
              )}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default NavLinkGroupDesktop;

import { useRouter } from 'next/router';
import React from 'react';

import config from 'configs/app';
import { rightLineArrow } from 'lib/html-entities';
import type {
  NavItemInternal,
  NavItem,
  NavGroupItem,
} from 'types/client/navigation-items';
import UserAvatar from 'ui/shared/UserAvatar';

interface ReturnType {
  mainNavItems: Array<NavItem | NavGroupItem>;
  accountNavItems: Array<NavItem>;
  profileItem: NavItem;
}

export function isGroupItem(
  item: NavItem | NavGroupItem
): item is NavGroupItem {
  return 'subItems' in item;
}

export function isInternalItem(item: NavItem): item is NavItemInternal {
  return 'nextRoute' in item;
}

export default function useNavItems(): ReturnType {
  const router = useRouter();
  const { pathname } = router;

  return React.useMemo(() => {
    let blockchainNavItems: Array<NavItem> | Array<Array<NavItem>> = [];

    const topAccounts: NavItem | null = !config.UI.views.address.hiddenViews
      ?.top_accounts
      ? {
          text: 'Top accounts',
          nextRoute: { pathname: '/accounts' as const },
          icon: 'top-accounts',
          isActive: pathname === '/accounts',
        }
      : null;
    const blocks: NavItem | null = {
      text: 'Blocks',
      nextRoute: { pathname: '/blocks' as const },
      icon: 'block-sidebar',
      isActive:
        pathname === '/blocks' || pathname === '/block/[height_or_hash]',
    };
    const txs: NavItem | null = {
      text: 'Transactions',
      nextRoute: { pathname: '/txs' as const },
      icon: 'transactions-sidebar',
      isActive: pathname === '/txs' || pathname === '/tx/[hash]',
    };
    const verifiedContracts: NavItem | null = {
      text: 'Verified contracts',
      nextRoute: { pathname: '/verified-contracts' as const },
      icon: 'verified',
      isActive: pathname === '/verified-contracts',
    };

    if (config.features.zkEvmRollup.isEnabled) {
      blockchainNavItems = [
        [
          txs,
          blocks,
          {
            text: 'Txn batches',
            nextRoute: { pathname: '/zkevm-l2-txn-batches' as const },
            icon: 'txn_batches',
            isActive:
              pathname === '/zkevm-l2-txn-batches' ||
              pathname === '/zkevm-l2-txn-batch/[number]',
          },
        ],
        [topAccounts, verifiedContracts].filter(Boolean),
      ];
    } else if (config.features.optimisticRollup.isEnabled) {
      blockchainNavItems = [
        [
          txs,
          // eslint-disable-next-line max-len
          {
            text: `Deposits (L1${rightLineArrow}L2)`,
            nextRoute: { pathname: '/l2-deposits' as const },
            icon: 'arrows/south-east',
            isActive: pathname === '/l2-deposits',
          },
          // eslint-disable-next-line max-len
          {
            text: `Withdrawals (L2${rightLineArrow}L1)`,
            nextRoute: { pathname: '/l2-withdrawals' as const },
            icon: 'arrows/north-east',
            isActive: pathname === '/l2-withdrawals',
          },
        ],
        [
          blocks,
          // eslint-disable-next-line max-len
          {
            text: 'Txn batches',
            nextRoute: { pathname: '/l2-txn-batches' as const },
            icon: 'txn_batches',
            isActive: pathname === '/l2-txn-batches',
          },
          // eslint-disable-next-line max-len
          {
            text: 'Output roots',
            nextRoute: { pathname: '/l2-output-roots' as const },
            icon: 'output_roots',
            isActive: pathname === '/l2-output-roots',
          },
        ],
        [topAccounts, verifiedContracts].filter(Boolean),
      ];
    } else {
      blockchainNavItems = [
        txs,
        blocks,
        topAccounts,
        config.features.beaconChain.isEnabled && {
          text: 'Withdrawals',
          nextRoute: { pathname: '/withdrawals' as const },
          icon: 'arrows/north-east',
          isActive: pathname === '/withdrawals',
        },
      ].filter(Boolean);
    }

    const mainNavItems: ReturnType['mainNavItems'] = [
      {
        text: 'Blockchain',
        icon: 'icon_blockchain',
        isActive: blockchainNavItems
          .flat()
          .some((item) => isInternalItem(item) && item.isActive),
        subItems: blockchainNavItems,
      },
      {
        text: 'Tokens',
        nextRoute: { pathname: '/tokens' as const },
        icon: 'icon_tokens',
        isActive: pathname.startsWith('/token'),
      },
      {
        text: 'Bridge',
        nextRoute: { pathname: '/bridge' as const },
        icon: 'icon_bridge',
        isActive: pathname.startsWith('/bridge'),
      },
      {
        text: 'Validators',
        nextRoute: { pathname: '/validators' as const },
        icon: 'icon_validators',
        isActive: pathname.startsWith('/validators'),
      },
      config.features.marketplace.isEnabled
        ? {
            text: 'DApps',
            nextRoute: { pathname: '/apps' as const },
            icon: 'apps',
            isActive: pathname.startsWith('/app'),
          }
        : null,

      config.features.stats.isEnabled
        ? {
            text: 'Charts & Stats',
            nextRoute: { pathname: '/stats' as const },
            icon: 'stats',
            isActive: pathname === '/stats',
          }
        : null,
      // apiNavItems.length > 0 && {
      //   text: 'API',
      //   icon: 'icon_api',
      //   isActive: apiNavItems.some(item => isInternalItem(item) && item.isActive),
      //   subItems: apiNavItems,
      // },
      {
        text: 'Other',
        icon: 'icon_other',
        subItems: [
          {
            text: 'Verify contract',
            icon: 'icon_validators',
            nextRoute: { pathname: '/contract-verification' as const },
            isActive: pathname.startsWith('/contract-verification'),
          },
          ...config.UI.sidebar.otherLinks,
        ],
      },
    ].filter(Boolean);

    const accountNavItems: ReturnType['accountNavItems'] = [
      {
        text: 'Watch list',
        nextRoute: { pathname: '/account/watchlist' as const },
        icon: 'watchlist',
        isActive: pathname === '/account/watchlist',
      },
      {
        text: 'Private tags',
        nextRoute: { pathname: '/account/tag-address' as const },
        icon: 'privattags',
        isActive: pathname === '/account/tag-address',
      },
      {
        text: 'Public tags',
        nextRoute: { pathname: '/account/public-tags-request' as const },
        icon: 'publictags',
        isActive: pathname === '/account/public-tags-request',
      },
      {
        text: 'API keys',
        nextRoute: { pathname: '/account/api-key' as const },
        icon: 'API',
        isActive: pathname === '/account/api-key',
      },
      {
        text: 'Custom ABI',
        nextRoute: { pathname: '/account/custom-abi' as const },
        icon: 'ABI',
        isActive: pathname === '/account/custom-abi',
      },
      config.features.addressVerification.isEnabled && {
        text: 'Verified addrs',
        nextRoute: { pathname: '/account/verified-addresses' as const },
        icon: 'verified',
        isActive: pathname === '/account/verified-addresses',
      },
    ].filter(Boolean);

    const profileItem = {
      text: 'My profile',
      nextRoute: { pathname: '/auth/profile' as const },
      iconComponent: UserAvatar,
      isActive: pathname === '/auth/profile',
    };

    return { mainNavItems, accountNavItems, profileItem };
  }, [pathname]);
}

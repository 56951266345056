import { ChakraProps, useColorModeValue } from '@chakra-ui/react';
import { Image, Skeleton, chakra } from '@chakra-ui/react';
import _omit from 'lodash/omit';
import { route } from 'nextjs-routes';
import React from 'react';

import type { TokenInfo } from 'types/api/token';

import TokenLogoPlaceholder from 'ui/shared/TokenLogoPlaceholder';
import TruncatedTextTooltip from 'ui/shared/TruncatedTextTooltip';
import * as EntityBase from 'ui/shared/entities/base/components';

import { getIconProps } from '../base/utils';

type LinkProps = EntityBase.LinkBaseProps & Pick<EntityProps, 'token'>;

const Link = chakra((props: LinkProps) => {
  const defaultHref = route({
    pathname: '/token/[hash]',
    query: { ...props.query, hash: props.token.address },
  });

  return (
    <EntityBase.Link {...props} href={props.href ?? defaultHref}>
      {props.children}
    </EntityBase.Link>
  );
});

type IconProps = Pick<
  EntityProps,
  'token' | 'isLoading' | 'iconSize' | 'noIcon' | 'className'
> & {
  marginRight?: ChakraProps['marginRight'];
  boxSize?: ChakraProps['boxSize'];
};

const Icon = (props: IconProps) => {
  if (props.noIcon) {
    return null;
  }

  const styles = {
    marginRight: props.marginRight ?? 2,
    boxSize: props.boxSize ?? getIconProps(props.iconSize).boxSize,
    borderRadius: '4px',
    flexShrink: 0,
  };

  if (props.isLoading) {
    return <Skeleton {...styles} className={props.className} />;
  }

  return (
    <Image
      {...styles}
      borderRadius={props.token.type === 'ERC-20' ? '4px' : '4px'}
      className={props.className}
      src={props.token.icon_url ?? undefined}
      alt={`${props.token.name || 'token'} logo`}
      fallback={<TokenLogoPlaceholder {...styles} />}
    />
  );
};

type ContentProps = Omit<EntityBase.ContentBaseProps, 'text'> &
  Pick<EntityProps, 'token' | 'jointSymbol' | 'onlySymbol'>;

const Content = chakra((props: ContentProps) => {
  const color = useColorModeValue('#1A1A1A', '#FFFFFF');
  const nameString = [
    !props.onlySymbol && (props.token.name ?? 'Unnamed token'),
    props.onlySymbol && (props.token.symbol ?? ''),
    props.token.symbol &&
      props.jointSymbol &&
      !props.onlySymbol &&
      `(${props.token.symbol})`,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <TruncatedTextTooltip label={nameString}>
      <Skeleton
        isLoaded={!props.isLoading}
        display="inline-block"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        height="fit-content"
        color={color}
      >
        {nameString}
      </Skeleton>
    </TruncatedTextTooltip>
  );
});

type SymbolProps = Pick<
  EntityProps,
  'token' | 'isLoading' | 'noSymbol' | 'jointSymbol' | 'onlySymbol'
>;

const Symbol = (props: SymbolProps) => {
  const { symbol } = props.token;
  const color = useColorModeValue('#1A1A1A99', '#eeeeee66');

  if (!symbol || props.noSymbol || props.jointSymbol || props.onlySymbol) {
    return null;
  }

  return (
    <Skeleton
      isLoaded={!props.isLoading}
      display="inline-flex"
      alignItems="center"
      maxW="20%"
      ml={2}
      color={color}
    >
      <div>(</div>
      <TruncatedTextTooltip label={symbol}>
        <chakra.span
          display="inline-block"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          height="fit-content"
        >
          {symbol}
        </chakra.span>
      </TruncatedTextTooltip>
      <div>)</div>
    </Skeleton>
  );
};

type CopyProps = Omit<EntityBase.CopyBaseProps, 'text'> &
  Pick<EntityProps, 'token'>;

const Copy = (props: CopyProps) => (
  <EntityBase.Copy {...props} text={props.token.address} />
);

const { Container } = EntityBase;

export interface EntityProps extends EntityBase.EntityBaseProps {
  token: Pick<TokenInfo, 'address' | 'icon_url' | 'name' | 'symbol' | 'type'>;
  noSymbol?: boolean;
  jointSymbol?: boolean;
  onlySymbol?: boolean;
}

const TokenEntity = (props: EntityProps) => {
  const linkProps = _omit(props, ['className']);
  const partsProps = _omit(props, ['className', 'onClick']);

  return (
    <Container className={props.className} w="100%">
      <Icon {...partsProps} />
      <Link {...linkProps}>
        <Content {...partsProps} />
      </Link>
      <Symbol {...partsProps} />
      <Copy {...partsProps} />
    </Container>
  );
};

export default React.memo(chakra(TokenEntity));

export { Container, Link, Icon, Content, Copy };

import {
  Button,
  forwardRef,
  chakra,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';

import getDefaultTransitionProps from 'theme/utils/getDefaultTransitionProps';
import IconSvg from 'ui/shared/IconSvg';

interface Props {
  isMobile?: boolean;
  isActive?: boolean;
  onClick?: () => void;
  className?: string;
}

const NetworkMenuButton = (
  { isMobile, isActive, onClick: handleClick, className }: Props,
  ref: React.ForwardedRef<HTMLButtonElement>
) => {
  const defaultIconColor = useColorModeValue('#1a1a1a', '#ffffff3d');
  const bgColor = '#ffffff14';
  const iconColor = '#FF474C';

  return (
    <Button
      className={className}
      variant="unstyled"
      display="inline-flex"
      alignItems="center"
      ref={ref}
      h="36px"
      w="36px"
      p={2}
      borderRadius="4px"
      backgroundColor={isActive ? bgColor : 'none'}
      onClick={handleClick}
      aria-label="Network menu"
      aria-roledescription="menu"
    >
      <IconSvg
        name="networks"
        width="19px"
        height="19px"
        color={isActive ? iconColor : defaultIconColor}
        _hover={{ color: isMobile ? undefined : 'link_hovered' }}
        cursor="pointer"
        {...getDefaultTransitionProps({ transitionProperty: 'margin' })}
      />
    </Button>
  );
};

export default chakra(forwardRef(NetworkMenuButton));

import {
  PopoverContent,
  PopoverBody,
  Text,
  VStack,
  Skeleton,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';

import type { FeaturedNetwork, NetworkGroup } from 'types/networks';

import NetworkMenuLink from './NetworkMenuLink';

interface Props {
  tabs: Array<NetworkGroup>;
  items?: Array<FeaturedNetwork>;
}

const NetworkMenuPopup = ({ items, tabs }: Props) => {
  const bgColor = useColorModeValue('#ffffff', '#282828');
  const titleColor = useColorModeValue('#1a1a1a99', '#ffffff');

  const content =
    !items || items.length === 0 ? (
      <>
        <Skeleton h="30px" w="120px" />
        <Flex mt={4} alignItems="center">
          <Flex h="40px" w="105px" px={4} py={2}>
            <Skeleton h="24px" w="100%" />
          </Flex>
          <Skeleton h="24px" w="68px" mx={4} />
          <Skeleton h="24px" w="45px" mx={4} />
        </Flex>
        <Flex mt={8} flexDir="column" rowGap={2}>
          <Flex mx={4} my={2} alignItems="center">
            <Skeleton h="30px" w="30px" borderRadius="full" />
            <Skeleton h="24px" w="120px" ml={3} />
          </Flex>
          <Flex mx={4} my={2} alignItems="center">
            <Skeleton h="30px" w="30px" borderRadius="full" />
            <Skeleton h="24px" w="180px" ml={3} />
          </Flex>
          <Flex mx={4} my={2} alignItems="center">
            <Skeleton h="30px" w="30px" borderRadius="full" />
            <Skeleton h="24px" w="150px" ml={3} />
          </Flex>
        </Flex>
      </>
    ) : (
      <>
        <Text as="h4" fontSize="20px" fontWeight="700" color={titleColor}>
          Networks
        </Text>
        <VStack as="ul" spacing="24px" alignItems="stretch" mt="20px">
          {items.map((network) => (
            <NetworkMenuLink key={network.title} {...network} />
          ))}
        </VStack>
      </>
    );

  return (
    <PopoverContent w="263px" backgroundColor={bgColor} borderRadius="4px">
      <PopoverBody padding="20px">{content}</PopoverBody>
    </PopoverContent>
  );
};

export default React.memo(NetworkMenuPopup);

import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

import type { GasPrices } from 'types/api/stats';

const GasInfoTooltipContent = ({ gasPrices }: { gasPrices: GasPrices }) => {
  const nameStyleProps = {
    color: useColorModeValue('link_hovered', 'link_hovered'),
    fontSize: '16px',
    fontWeight: '500',
  };

  const valueStyleProps = {
    color: useColorModeValue('#1a1a1a', '#ffffff'),
    fontSize: '14px',
    fontWeight: '400',
  };

  return (
    <Grid
      templateColumns="repeat(2, max-content)"
      rowGap={2}
      borderRadius="4px"
      columnGap={4}
      padding="12px"
    >
      <GridItem {...nameStyleProps}>Slow</GridItem>
      <GridItem {...valueStyleProps}>
        {gasPrices.slow !== null ? `${gasPrices.slow} Gwei` : 'N/A'}
      </GridItem>
      <GridItem {...nameStyleProps}>Average</GridItem>
      <GridItem {...valueStyleProps}>
        {gasPrices.average !== null ? `${gasPrices.average} Gwei` : 'N/A'}
      </GridItem>
      <GridItem {...nameStyleProps}>Fast</GridItem>
      <GridItem {...valueStyleProps}>
        {gasPrices.fast !== null ? `${gasPrices.fast} Gwei` : 'N/A'}
      </GridItem>
    </Grid>
  );
};

export default React.memo(GasInfoTooltipContent);

import { Center, Link, Skeleton, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

import type { IconName } from 'ui/shared/IconSvg';
import IconSvg from 'ui/shared/IconSvg';

type Props = {
  icon?: IconName;
  iconSize?: string;
  text: string;
  url: string;
  isLoading?: boolean;
};

const FooterLinkItem = ({ icon, iconSize, text, url, isLoading }: Props) => {
  const color = useColorModeValue('#747474', '#8F959E');

  if (isLoading) {
    return <Skeleton my="3px">{text}</Skeleton>;
  }

  return (
    <Link
      href={url}
      display="flex"
      width="fit-content"
      variant="white"
      alignItems="center"
      target="_blank"
      fontSize="12px"
      whiteSpace="nowrap"
      h="20px"
      fontWeight={400}
      color={color}
    >
      {icon && (
        <Center minW={6} mr={2}>
          <IconSvg boxSize={iconSize || 5} name={icon} />
        </Center>
      )}
      {text}
    </Link>
  );
};

export default FooterLinkItem;

import {
  Flex,
  Link,
  Skeleton,
  Tooltip,
  chakra,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';
import useApiQuery from 'lib/api/useApiQuery';
import { HOMEPAGE_STATS } from 'stubs/stats';
import GasInfoTooltipContent from 'ui/shared/GasInfoTooltipContent/GasInfoTooltipContent';
import TextSeparator from 'ui/shared/TextSeparator';

const TopBarStats = () => {
  // have to implement controlled tooltip because of the issue - https://github.com/chakra-ui/chakra-ui/issues/7107
  const color = useColorModeValue('#1a1a1a', '#ffffff');
  const tooltipBgColor = useColorModeValue('#ffffff', '#282828');
  const {
    isOpen,
    onOpen: handleOpen,
    onToggle,
    onClose: handleClose,
  } = useDisclosure();

  const handleClick = React.useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      onToggle();
    },
    [onToggle]
  );

  const { data, isPlaceholderData, isError } = useApiQuery('homepage_stats', {
    queryOptions: {
      placeholderData: HOMEPAGE_STATS,
      refetchOnMount: false,
    },
  });

  if (isError) {
    return <div />;
  }

  return (
    <Flex alignItems="center" fontSize="xs" fontWeight={500}>
      {data?.coin_price && (
        <Skeleton isLoaded={!isPlaceholderData}>
          <chakra.span color={color}>
            {config.chain.governanceToken.symbol ||
              config.chain.currency.symbol}{' '}
          </chakra.span>
          <chakra.span color="#FF474C">
            $
            {Number(data.coin_price).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 6,
            })}
          </chakra.span>
        </Skeleton>
      )}
      {data?.coin_price && config.UI.homepage.showGasTracker && (
        <TextSeparator color="divider" />
      )}
      {data?.gas_prices &&
        data.gas_prices.average !== null &&
        config.UI.homepage.showGasTracker && (
          <Skeleton isLoaded={!isPlaceholderData}>
            <chakra.span color={color}>Gas </chakra.span>
            <Tooltip
              label={<GasInfoTooltipContent gasPrices={data.gas_prices} />}
              hasArrow={false}
              borderRadius="4px"
              offset={[0, 16]}
              bgColor={tooltipBgColor}
              p={0}
              isOpen={isOpen}
            >
              <Link
                onClick={handleClick}
                onMouseEnter={handleOpen}
                onMouseLeave={handleClose}
              >
                {data.gas_prices.average} Gwei
              </Link>
            </Tooltip>
          </Skeleton>
        )}
    </Flex>
  );
};

export default React.memo(TopBarStats);

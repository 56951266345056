import type { StyleFunctionProps } from '@chakra-ui/theme-tools';
import { mode } from '@chakra-ui/theme-tools';

export default function getDefaultFormColors(props: StyleFunctionProps) {
  const {
    focusBorderColor: fc,
    errorBorderColor: ec,
    filledBorderColor: flc,
  } = props;
  return {
    focusBorderColor: fc || mode('#FFFFFF', '#FFFFFF')(props),
    focusPlaceholderColor: fc || '#FFFFFF',
    errorColor: ec || mode('#FD4E4E', '#FD4E4E99')(props),
    filledColor: flc || mode('#FFFFFF66', '#FFFFFF66')(props),
  };
}

import { HStack, Box } from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';
import NetworkLogo from 'ui/snippets/networkMenu/NetworkLogo';
import ProfileMenuDesktop from 'ui/snippets/profileMenu/ProfileMenuDesktop';
import SearchBar from 'ui/snippets/searchBar/SearchBar';
import WalletMenuDesktop from 'ui/snippets/walletMenu/WalletMenuDesktop';

import Burger from './Burger';

type Props = {
  renderSearchBar?: () => React.ReactNode;
  isMarketplaceAppPage?: boolean;
};

const HeaderDesktop = ({ renderSearchBar, isMarketplaceAppPage }: Props) => {
  const searchBar = renderSearchBar ? renderSearchBar() : <SearchBar />;

  return (
    <HStack
      as="header"
      display={{ base: 'none', lg: 'flex' }}
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      {/*// Header with webview case (DApps or Swap)*/}
      {/*{isMarketplaceAppPage && (*/}
      {/*  <Box display="flex" alignItems="center" gap={3}>*/}
      {/*    <Burger isMarketplaceAppPage />*/}
      {/*    <NetworkLogo isCollapsed />*/}
      {/*  </Box>*/}
      {/*)}*/}
      <Box width="100%">{searchBar}</Box>
    </HStack>
  );
};

export default React.memo(HeaderDesktop);

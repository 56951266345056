import { CustomLinksGroup } from '../types/footerLinks';

export const footerLinks: Array<CustomLinksGroup> = [
  {
    title: 'Bifrost',
    links: [
      {
        text: 'Bifrost Network',
        url: 'https://bifrostnetwork.com/ ',
      },
      {
        text: 'X (ex-Twitter)',
        url: 'https://x.com/bifrost_network',
      },
      {
        text: 'Discord',
        url: 'https://discord.com/invite/bifrostcity',
      },
      {
        text: 'BTCFi',
        url: 'https://www.btcfi.one/',
      },
      {
        text: 'Pockie',
        url: 'https://www.pockie.io/',
      },
      {
        text: 'BiFi',
        url: 'https://crosschain.bifi.finance/',
      },
      {
        text: 'BiFiX',
        url: 'https://x.bifi.finance/',
      },
      {
        text: 'Bridge',
        url: 'https://bifrostnetwork.com/bridge',
      },
    ],
  },
];

export default footerLinks;

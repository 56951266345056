import { Box, Flex, Link, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

import useIsMobile from 'lib/hooks/useIsMobile';
import {
  clearRecentSearchKeywords,
  getRecentSearchKeywords,
  removeRecentSearchKeyword,
} from 'lib/recentSearchKeywords';
import ClearButton from 'ui/shared/ClearButton';
import HashStringShortenDynamic from 'ui/shared/HashStringShortenDynamic';

type Props = {
  onClick: (kw: string) => void;
  onClear: () => void;
};

const SearchBarSuggest = ({ onClick, onClear }: Props) => {
  const bgHoverColor = useColorModeValue('#ffffff29', '#ffffff29');
  const titleColor = useColorModeValue('#1A1A1A99', '#8F959E');

  const [keywords, setKeywords] = React.useState<Array<string>>(
    getRecentSearchKeywords()
  );

  const handleClick = React.useCallback(
    (kw: string) => () => {
      onClick(kw);
    },
    [onClick]
  );

  const handleClearKeywords = React.useCallback(() => {
    clearRecentSearchKeywords();
    onClear();
  }, [onClear]);

  const handleRemoveKeyword = React.useCallback(
    (kw: string) => (e: React.SyntheticEvent) => {
      e.stopPropagation();
      const result = keywords.filter((item) => item !== kw);
      setKeywords(result);
      if (result.length === 0) {
        onClear();
      }
      removeRecentSearchKeyword(kw);
    },
    [keywords, onClear]
  );

  if (keywords.length === 0) {
    return null;
  }

  return (
    <Box py={4}>
      <Flex mb={3} justifyContent="space-between" fontSize="sm">
        <Text fontWeight={600} color={titleColor}>
          Recent
        </Text>
        <Link color="#749BFF" onClick={handleClearKeywords}>
          Clear all
        </Link>
      </Flex>
      {keywords.map((kw) => (
        <Flex
          key={kw}
          py={3}
          px={1}
          borderColor="divider"
          borderRadius="4px"
          borderBottomWidth="1px"
          _last={{
            borderBottomWidth: '0',
          }}
          _hover={{
            bgColor: bgHoverColor,
          }}
          fontSize="sm"
          _first={{
            mt: 2,
          }}
          onClick={handleClick(kw)}
          alignItems="center"
          justifyContent="space-between"
          cursor="pointer"
          columnGap={2}
          fontWeight={700}
          minW={0}
          flexGrow={1}
        >
          {kw.startsWith('0x') ? (
            <Box overflow="hidden" whiteSpace="nowrap">
              <HashStringShortenDynamic hash={kw} isTooltipDisabled />
            </Box>
          ) : (
            <Text overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
              {kw}
            </Text>
          )}
          <ClearButton onClick={handleRemoveKeyword(kw)} flexShrink={0} />
        </Flex>
      ))}
    </Box>
  );
};

export default SearchBarSuggest;

import {
  Box,
  Tab,
  TabList,
  Tabs,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import type { UseQueryResult } from '@tanstack/react-query';
import throttle from 'lodash/throttle';
import React from 'react';
import { scroller, Element } from 'react-scroll';

import type { ResourceError } from 'lib/api/resources';
import useIsMobile from 'lib/hooks/useIsMobile';
import type { SearchResultItem } from 'types/api/search';
import useMarketplaceApps from 'ui/marketplace/useMarketplaceApps';
import ContentLoader from 'ui/shared/ContentLoader';
import type { ApiCategory, ItemsCategoriesMap } from 'ui/shared/search/utils';
import { getItemCategory, searchCategories } from 'ui/shared/search/utils';

import SearchBarSuggestApp from './SearchBarSuggestApp';
import SearchBarSuggestItem from './SearchBarSuggestItem';

interface Props {
  query: UseQueryResult<Array<SearchResultItem>, ResourceError<unknown>>;
  searchTerm: string;
  onItemClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  containerId: string;
}

const SearchBarSuggest = ({
  query,
  searchTerm,
  onItemClick: handleItemClick,
  containerId,
}: Props) => {
  const isMobile = useIsMobile();

  const marketplaceApps = useMarketplaceApps(searchTerm);

  const categoriesRefs = React.useRef<Array<HTMLParagraphElement>>([]);
  const tabsRef = React.useRef<HTMLDivElement>(null);
  const elementsRefs = React.useRef<Array<HTMLDivElement>>([]);

  const [tabIndex, setTabIndex] = React.useState(0);

  const handleScroll = React.useCallback(() => {
    const container = document.getElementById(containerId);
    if (!container || !query.data?.length) {
      return;
    }

    const topLimit =
      container.getBoundingClientRect().y +
      (tabsRef.current?.clientHeight || 0) +
      24;

    const bottomLimit = container.getBoundingClientRect().bottom;

    if (
      elementsRefs.current[
        elementsRefs.current.length - 1
      ].getBoundingClientRect().bottom <= bottomLimit
    ) {
      setTabIndex(elementsRefs.current.length - 1);
      return;
    }

    for (let i = 0; i < categoriesRefs.current.length - 1; i++) {
      if (
        categoriesRefs.current[i].getBoundingClientRect().y <= topLimit &&
        categoriesRefs.current[i + 1].getBoundingClientRect().y > topLimit
      ) {
        setTabIndex(i);
        break;
      }
    }
  }, [containerId, query.data]);

  React.useEffect(() => {
    const container = document.getElementById(containerId);
    const throttledHandleScroll = throttle(handleScroll, 300);
    if (container) {
      container.addEventListener('scroll', throttledHandleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', throttledHandleScroll);
      }
    };
  }, [containerId, handleScroll]);

  const itemsGroups = React.useMemo(() => {
    if (!query.data && !marketplaceApps.displayedApps) {
      return {};
    }
    const map: Partial<ItemsCategoriesMap> = {};
    query.data?.forEach((item) => {
      const cat = getItemCategory(item) as ApiCategory;
      if (cat) {
        if (cat in map) {
          map[cat]?.push(item);
        } else {
          map[cat] = [item];
        }
      }
    });
    if (marketplaceApps.displayedApps.length) {
      map.app = marketplaceApps.displayedApps;
    }
    return map;
  }, [query.data, marketplaceApps.displayedApps]);

  React.useEffect(() => {
    categoriesRefs.current = Array(Object.keys(itemsGroups).length)
      .fill('')
      .map((_, i) => categoriesRefs.current[i] || React.createRef());
  }, [itemsGroups]);

  const handleScrollToCategory = React.useCallback(
    (index: number) => () => {
      scroller.scrollTo(`cat_${index}`, {
        duration: 250,
        smooth: true,
        offset: -(tabsRef.current?.clientHeight || 0),
        containerId,
      });
    },
    [containerId]
  );

  const bgColor = useColorModeValue('#ffffff', '#282828');

  const content = (() => {
    if (query.isPending || marketplaceApps.isPlaceholderData) {
      return (
        <ContentLoader
          w="100%"
          text="We are searching, please wait... "
          fontSize="sm"
        />
      );
    }

    if (query.isError) {
      return (
        <Text>
          Something went wrong. Try refreshing the page or come back later.
        </Text>
      );
    }

    if (!query.data || query.data.length === 0) {
      return <Text>No results found.</Text>;
    }

    const resultCategories = searchCategories.filter(
      (cat) => itemsGroups[cat.id]
    );

    return (
      <>
        {resultCategories.length > 1 && (
          <Box
            position="sticky"
            top="0"
            width="100%"
            backgroundColor={bgColor}
            py={5}
            my={-5}
            ref={tabsRef}
          >
            <Tabs variant="lightgray" size="sm" index={tabIndex}>
              <TabList columnGap={3} rowGap={2} flexWrap="wrap">
                {resultCategories.map((cat, index) => (
                  <Tab key={cat.id} onClick={handleScrollToCategory(index)}>
                    {cat.title}
                  </Tab>
                ))}
              </TabList>
            </Tabs>
          </Box>
        )}
        {resultCategories.map((cat, indx) => (
          <div
            key={cat.id}
            /* eslint-disable-next-line react/jsx-no-bind */
            ref={(el: HTMLDivElement) => (elementsRefs.current[indx] = el)}
          >
            <Element name={`cat_${indx}`}>
              <Text
                fontSize="16px"
                fontWeight={700}
                variant="secondary"
                mt={6}
                mb={3}
                /* eslint-disable-next-line react/jsx-no-bind */
                ref={(el: HTMLParagraphElement) =>
                  (categoriesRefs.current[indx] = el)
                }
              >
                {cat.title}
              </Text>
              {cat.id !== 'app' &&
                itemsGroups[cat.id]?.map((item, index) => (
                  <SearchBarSuggestItem
                    key={index}
                    data={item}
                    isMobile={isMobile}
                    searchTerm={searchTerm}
                    onClick={handleItemClick}
                  />
                ))}
              {cat.id === 'app' &&
                itemsGroups[cat.id]?.map((item, index) => (
                  <SearchBarSuggestApp
                    key={index}
                    data={item}
                    isMobile={isMobile}
                    searchTerm={searchTerm}
                    onClick={handleItemClick}
                  />
                ))}
            </Element>
          </div>
        ))}
      </>
    );
  })();

  return (
    <Box mt={5} mb={5}>
      {content}
    </Box>
  );
};

export default SearchBarSuggest;

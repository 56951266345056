import React from 'react';

import { MarketplaceCategory } from 'types/client/marketplace';
import type { MarketplaceAppOverview } from 'types/client/marketplace';
import dAppsList from '../../configs/dapps';


function isAppNameMatches(q: string, app: MarketplaceAppOverview) {
  return app.title.toLowerCase().includes(q.toLowerCase());
}

function isAppCategoryMatches(category: string, app: MarketplaceAppOverview, favoriteApps: Array<string>) {
  return category === MarketplaceCategory.ALL ||
      (category === MarketplaceCategory.FAVORITES && favoriteApps.includes(app.id)) ||
      app.categories.includes(category);
}

export default function useMarketplaceApps(filter: string, selectedCategoryId: string = MarketplaceCategory.ALL, favoriteApps: Array<string> = []) {
  const data = dAppsList;

  const displayedApps = React.useMemo(() => data?.filter(app => isAppNameMatches(filter, app) && isAppCategoryMatches(selectedCategoryId, app, favoriteApps)) || [], [ selectedCategoryId, data, filter, favoriteApps ]);

  return React.useMemo(() => ({
    data,
    displayedApps,
    error: "",
    isError: false,
    isPlaceholderData: false,
  }), [
    data,
    displayedApps,
  ]);
}

import { inputAnatomy as parts } from '@chakra-ui/anatomy';
import { Input as InputComponent } from '@chakra-ui/react';
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

import getDefaultTransitionProps from '../utils/getDefaultTransitionProps';
import getOutlinedFieldStyles from '../utils/getOutlinedFieldStyles';

const size = {
  xs: defineStyle({
    fontSize: '14px',
    lineHeight: '14px',
    px: '3px',
    py: '3px',
    h: '20px',
  }),
  sm: defineStyle({
    fontSize: '16px',
    lineHeight: '24px',
    px: '4px',
    py: '2px',
    h: '32px',
  }),
  md: defineStyle({
    fontSize: 'md',
    lineHeight: '20px',
    px: '20px',
    py: '20px',
    h: '60px',
    borderRadius: '4px',
  }),
  lg: defineStyle({
    fontSize: 'md',
    lineHeight: '20px',
    px: '24px',
    py: '28px',
    h: '80px',
    borderRadius: '4px',
  }),
};

const variantOutline = definePartsStyle((props) => {
  const transitionProps = getDefaultTransitionProps();

  return {
    field: getOutlinedFieldStyles(props),
    addon: {
      ...transitionProps,
    },
  };
});

const sizes = {
  xs: definePartsStyle({
    field: size.xs,
    addon: size.xs,
  }),
  sm: definePartsStyle({
    field: size.sm,
    addon: size.sm,
  }),
  md: definePartsStyle({
    field: size.md,
    addon: size.md,
  }),
  lg: definePartsStyle({
    field: size.lg,
    addon: size.lg,
  }),
};

const variants = {
  outline: variantOutline,
};

const Input = defineMultiStyleConfig({
  sizes,
  variants,
  defaultProps: {
    size: 'md',
    variant: 'outline',
  },
});

InputComponent.defaultProps = {
  ...InputComponent.defaultProps,
  placeholder: ' ',
};

export default Input;

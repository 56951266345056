import { chakra, IconButton, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

import IconSvg from 'ui/shared/IconSvg';

interface Props {
  onClick: (e: React.SyntheticEvent) => void;
  isDisabled?: boolean;
  className?: string;
}

const ClearButton = ({
  onClick: handleClick,
  isDisabled,
  className,
}: Props) => {
  const iconColor = useColorModeValue('#1A1A1A99', '#FFFFFFCC');
  const iconColorHover = useColorModeValue('#1A1A1A99', '#FFFFFFCC');

  return (
    <IconButton
      isDisabled={isDisabled}
      className={className}
      colorScheme="none"
      aria-label="Clear input"
      title="Clear input"
      boxSize={6}
      bgColor="transparent"
      _hover={{ bgColor: 'transparent' }}
      icon={
        <IconSvg
          name="status/error"
          boxSize={3}
          color={iconColor}
          _hover={{ color: iconColorHover }}
        />
      }
      size="sm"
      onClick={handleClick}
    />
  );
};

export default chakra(ClearButton);

import { Flex, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

import ColorModeSwitch from './ColorModeSwitch';
import TopBarStats from './TopBarStats';

const TopBar = () => {
  const bgColor = useColorModeValue('#1a1a1a14', '#ffffff14');

  return (
    <Flex
      px={2}
      bgColor={bgColor}
      justifyContent="space-between"
      alignItems="center"
      h="36px"
    >
      <TopBarStats />
      <ColorModeSwitch />
    </Flex>
  );
};

export default React.memo(TopBar);

import { useColorModeValue } from '@chakra-ui/react';
import type { SystemStyleInterpolation } from '@chakra-ui/styled-system';
import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';

import getDefaultTransitionProps from '../utils/getDefaultTransitionProps';

const baseStyle = defineStyle(getDefaultTransitionProps());

const variantPrimary = defineStyle((props) => ({
  color: mode('#4466A8', '#749BFF')(props),
  // _hover: {
  //   color: useColorModeValue('#4466A8', '#749BFF'),
  //   textDecorationStyle: props.textDecorationStyle || 'solid',
  // },
}));

const variantSecondary = defineStyle((props) => ({
  color: mode('#FF5C28', '#BFC')(props),
  _hover: {
    color: mode('#FF5C28', '#BFC')(props),
    textDecorationStyle: props.textDecorationStyle || 'solid',
  },
}));

const variantThemeColor = defineStyle((props) => ({
  color: 'link_hovered',
  _hover: {
    color: 'link_hovered',
    textDecorationStyle: props.textDecorationStyle || 'solid',
  },
}));

const variantWhite = defineStyle((props) => ({
  _hover: {
    textDecorationStyle: props.textDecorationStyle || 'solid',
  },
}));

const variants: Record<string, SystemStyleInterpolation> = {
  primary: variantPrimary,
  secondary: variantSecondary,
  theme: variantThemeColor,
  white: variantWhite,
};

const defaultProps = {
  variant: 'primary',
};

const Link = defineStyleConfig({
  variants,
  defaultProps,
  baseStyle,
});

export default Link;

import { switchAnatomy as parts } from '@chakra-ui/anatomy';
import {
  defineStyle,
  createMultiStyleConfigHelpers,
} from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyleTrack = defineStyle((props) => ({
  width: '47px',
  height: '20px',
  borderWidth: 1,
  bg: mode(`#eeeeee66`, `#eeeeee66`)(props),
  borderColor: mode(`#ffffff33`, `#ffffff33`)(props),
  _checked: {
    bg: mode(`#FF767A`, `#FF767A`)(props),
    borderColor: mode(`#FF474C`, `#FF474C`)(props),
  },
}));

const baseStyleThumb = defineStyle((props) => ({
  width: '20px',
  height: '20px',
  backgroundImage: '/static/dark_mode.svg',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 50% top 50%',
  bg: mode(`#ffffff`, `#ffffff`)(props),
  _checked: {
    backgroundImage: '/static/light_mode.svg',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 50% top 50%',
    ml: '13px',
  },
}));

const baseStyle = definePartsStyle((props) => ({
  track: baseStyleTrack(props),
  thumb: baseStyleThumb(props),
}));

const Switch = defineMultiStyleConfig({
  baseStyle,
});

export default Switch;

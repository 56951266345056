import type { IconName } from 'ui/shared/IconSvg';

export const COLOR_THEMES = [
  {
    name: 'dark',
    colorMode: 'dark',
    icon: 'moon' as IconName,
    hex: '#1A1A1A',
  },
  {
    name: 'light',
    colorMode: 'light',
    icon: 'sun' as IconName,
    hex: '#fbf9f9',
  },
];

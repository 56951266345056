import { chakra, useColorModeValue } from '@chakra-ui/react';
import _omit from 'lodash/omit';
import { route } from 'nextjs-routes';
import React from 'react';

import * as EntityBase from 'ui/shared/entities/base/components';

type LinkProps = EntityBase.LinkBaseProps &
  Pick<EntityProps, 'hash' | 'number'>;

const Link = chakra((props: LinkProps) => {
  const heightOrHash = props.hash ?? String(props.number);
  const defaultHref = route({
    pathname: '/block/[height_or_hash]',
    query: { height_or_hash: heightOrHash },
  });

  return (
    <EntityBase.Link {...props} href={props.href ?? defaultHref}>
      {props.children}
    </EntityBase.Link>
  );
});

type IconProps = Omit<EntityBase.IconBaseProps, 'name'> & {
  name?: EntityBase.IconBaseProps['name'];
};

const Icon = (props: IconProps) => (
  <EntityBase.Icon {...props} name={props.name ?? 'block_slim'} />
);

type ContentProps = Omit<EntityBase.ContentBaseProps, 'text'> &
  Pick<EntityProps, 'number'>;

const Content = chakra((props: ContentProps) => (
  <EntityBase.Content
    {...props}
    text={String(props.number)}
    tailLength={props.tailLength ?? 2}
  />
));

const { Container } = EntityBase;

export interface EntityProps extends EntityBase.EntityBaseProps {
  number: number;
  hash?: string;
}

const BlockEntity = (props: EntityProps) => {
  const linkProps = _omit(props, ['className']);
  const partsProps = _omit(props, ['className', 'onClick']);
  const iconColor = useColorModeValue('#505050', '#ffffff');

  return (
    <Container className={props.className}>
      <Icon color={iconColor} {...partsProps} />
      <Link {...linkProps}>
        <Content {...partsProps} fontWeight={500} />
      </Link>
    </Container>
  );
};

export default React.memo(chakra(BlockEntity));

export { Container, Link, Icon, Content };

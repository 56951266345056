import { Flex, Box, VStack, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';
import * as cookies from 'lib/cookies';
import useNavItems, { isGroupItem } from 'lib/hooks/useNavItems';
import getDefaultTransitionProps from 'theme/utils/getDefaultTransitionProps';
import IconSvg from 'ui/shared/IconSvg';
import NetworkLogo from 'ui/snippets/networkMenu/NetworkLogo';
import NetworkMenu from 'ui/snippets/networkMenu/NetworkMenu';

import NavLink from './NavLink';
import NavLinkGroupDesktop from './NavLinkGroupDesktop';

const NavigationDesktop = () => {
  const borderColor = useColorModeValue('#1A1A1A14', '#FFFFFF14');
  const networkTagBgColor = useColorModeValue('#1a1a1a14', '#ffffff14');
  const { mainNavItems } = useNavItems();
  const [isCollapsed, setCollapsedState] = React.useState<boolean | undefined>(
    false
  );

  const handleTogglerClick = React.useCallback(() => {
    setCollapsedState((flag) => !flag);
    cookies.set(
      cookies.NAMES.NAV_BAR_COLLAPSED,
      isCollapsed ? 'false' : 'true'
    );
  }, [isCollapsed]);

  const chevronIconStyles = {
    bgColor: useColorModeValue('white', 'black'),
    color: useColorModeValue('blackAlpha.400', 'whiteAlpha.400'),
    borderColor,
  };

  const isExpanded = !isCollapsed;

  return (
    <Flex
      display={{ base: 'none', lg: 'flex' }}
      position="relative"
      flexDirection="column"
      alignItems="stretch"
      borderRight="1px solid"
      borderBottom="1px solid"
      borderColor={borderColor}
      px={{ lg: isExpanded ? 6 : 4, xl: isCollapsed ? 4 : 6 }}
      py={12}
      width={{
        lg: isExpanded ? '272px' : '80px',
        xl: isCollapsed ? '80px' : '272px',
      }}
      {...getDefaultTransitionProps({ transitionProperty: 'width, padding' })}
      sx={{
        '&:hover #expand-icon': {
          display: 'block',
        },
      }}
    >
      {config.chain.isTestnet && (
        <Flex
          bgColor={networkTagBgColor}
          color="#FF474C"
          p="4px"
          borderRadius="2px"
          fontSize="12px"
          lineHeight="12px"
          width="fit-content"
        >
          Testnet
        </Flex>
      )}
      <Box
        as="header"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
        overflow="hidden"
        h={10}
        transitionProperty="padding"
        transitionDuration="normal"
        transitionTimingFunction="ease"
      >
        <NetworkLogo isCollapsed={isCollapsed} />
        <NetworkMenu isCollapsed={isCollapsed} />
      </Box>
      <Box as="nav" mt={10} w="100%">
        <VStack as="ul" spacing="12px" alignItems="flex-start">
          {mainNavItems.map((item) => {
            if (isGroupItem(item)) {
              return (
                <NavLinkGroupDesktop
                  key={item.text}
                  item={item}
                  isCollapsed={isCollapsed}
                />
              );
            } else {
              return (
                <NavLink
                  key={item.text}
                  item={item}
                  isCollapsed={isCollapsed}
                />
              );
            }
          })}
        </VStack>
      </Box>
      <IconSvg
        name="arrows/east-mini"
        width={6}
        height={6}
        border="1px"
        _hover={{ color: 'link_hovered' }}
        borderRadius="base"
        {...chevronIconStyles}
        transform={isCollapsed ? 'rotate(180deg)' : 'rotate(0)'}
        {...getDefaultTransitionProps({
          transitionProperty: 'transform, left',
        })}
        transformOrigin="center"
        position="absolute"
        top="104px"
        left={isCollapsed ? '67px' : '258px'}
        cursor="pointer"
        onClick={handleTogglerClick}
        aria-label="Expand/Collapse menu"
        id="expand-icon"
        display="none"
      />
    </Flex>
  );
};

export default NavigationDesktop;

import { GridProps, useColorModeValue } from '@chakra-ui/react';
import {
  Box,
  Grid,
  Flex,
  Text,
  Link,
  VStack,
  Skeleton,
} from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';
import { footerLinks } from 'configs/footer_links';
import useApiQuery from 'lib/api/useApiQuery';
import useFetch from 'lib/hooks/useFetch';
import useIssueUrl from 'lib/hooks/useIssueUrl';
import NetworkAddToWallet from 'ui/shared/NetworkAddToWallet';

import FooterLinkItem from './FooterLinkItem';
import IntTxsIndexingStatus from './IntTxsIndexingStatus';
import getApiVersionUrl from './utils/getApiVersionUrl';

const Footer = () => {
  const containerProps: GridProps = {
    as: 'footer',
    px: { base: '16px', lg: '32px' },
    py: { base: '20px', lg: '20px' },
    borderColor: 'divider',
    justifyContent: 'space-between',
    height: 'fit-content',
  };

  return (
    <Flex {...containerProps}>
      {[...(footerLinks || [])].map((linkGroup) => (
        <Box key={linkGroup.title}>
          <Skeleton
            fontWeight={700}
            mb={{ base: '16px', lg: '8px' }}
            display="inline-block"
            isLoaded={!!footerLinks}
          >
            {linkGroup.title}
          </Skeleton>
          <Flex
            gap={{ base: '20px', lg: '28px' }}
            flexDirection={{ base: 'column', lg: 'row' }}
          >
            {linkGroup.links.map((link) => (
              <FooterLinkItem
                {...link}
                key={link.text}
                isLoading={!footerLinks}
              />
            ))}
          </Flex>
        </Box>
      ))}
      <NetworkAddToWallet />
    </Flex>
  );
};

export default React.memo(Footer);

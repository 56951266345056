import { tabsAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';
const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

import Button from './Button/Button';

const variantSoftRounded = definePartsStyle((props) => ({
  tab: {
    borderRadius: '4px',
    fontWeight: '600',
    opacity: 0.4,
    color: mode('blue.700', 'gray.400')(props),
    padding: '10px',
    _selected: {
      color: mode('white', '#white')(props),
      bg: '#ffffff14',
      opacity: 1,
      _hover: {
        color: mode('blue.700', 'gray.50')(props),
      },
    },
    _hover: {
      color: 'link_hovered',
    },
    _focusVisible: {
      boxShadow: { base: 'none', lg: 'outline' },
    },
  },
}));

const variantGray = definePartsStyle((props) => ({
  tab: {
    borderRadius: '4px',
    fontWeight: '700',
    color: mode('#1A1A1A99', '#ffffff')(props),
    opacity: 0.4,
    padding: '8px',
    _selected: {
      bg: mode('#9E9E9E1F', '#ffffff14')(props),
      color: mode('#1a1a1a', '#ffffff')(props),
      opacity: 1,
    },
    _hover: {
      color: mode('#1a1a1a', '#ffffff')(props),
    },
    _focusVisible: {
      boxShadow: { base: 'none', lg: 'outline' },
    },
  },
}));

const variantLightGray = definePartsStyle((props) => ({
  tab: {
    borderRadius: '4px',
    fontWeight: '500',
    fontSize: '16px',
    color: mode('#1A1A1A99', '#ffffff99')(props),
    paddingX: '8px',
    paddingY: '4px',
    borderWidth: '1px',
    borderColor: mode('#1a1a1a33', '#ffffff66')(props),
    _selected: {
      borderWidth: 0,
      backgroundColor: mode('#666', '#ffffff66')(props),
      color: 'white',
    },
  },
}));

const variantOutline = definePartsStyle((props) => ({
  tab: {
    ...Button.variants?.outline(props),
    ...Button.baseStyle,
    // eslint-disable-next-line no-underscore-dangle
    _selected: Button.variants?.outline(props)._active,
  },
}));

const sizes = {
  sm: definePartsStyle({
    tab: Button.sizes?.sm,
  }),
  md: definePartsStyle({
    tab: Button.sizes?.md,
  }),
};

const variants = {
  'soft-rounded': variantSoftRounded,
  outline: variantOutline,
  gray: variantGray,
  lightgray: variantLightGray,
};

const Tabs = defineMultiStyleConfig({
  sizes,
  variants,
});

export default Tabs;

import { Switch, FormControl, Text, useColorMode } from '@chakra-ui/react';
import React, { ChangeEvent, useMemo } from 'react';

import * as cookies from 'lib/cookies';
import { COLOR_THEMES } from './utils';

const ColorModeSwitch = () => {
  const { setColorMode, colorMode } = useColorMode();
  const isDark = useMemo(() => colorMode === 'dark', [colorMode]);

  const setTheme = React.useCallback(
    (hex: string) => {
      const nextTheme = COLOR_THEMES.find((theme) => theme.hex === hex);

      if (!nextTheme) {
        return;
      }

      setColorMode(nextTheme.colorMode);

      const varName = '--chakra-colors-black';
      window.document.documentElement.style.setProperty(varName, hex);

      cookies.set(cookies.NAMES.COLOR_MODE_HEX, hex);
      window.localStorage.setItem(
        cookies.NAMES.COLOR_MODE,
        nextTheme.colorMode
      );
    },
    [setColorMode]
  );

  React.useEffect(() => {
    const cookieColorMode = cookies.get(cookies.NAMES.COLOR_MODE);

    const nextColorMode = (() => {
      if (!cookieColorMode) {
        return window.matchMedia('(prefers-color-scheme: dark)').matches
          ? 'dark'
          : 'light';
      }

      return colorMode;
    })();

    const fallbackHex = (
      COLOR_THEMES.find((theme) => theme.colorMode === nextColorMode) ??
      COLOR_THEMES[0]
    ).hex;
    const cookieHex = cookies.get(cookies.NAMES.COLOR_MODE_HEX) ?? fallbackHex;
    setTheme(cookieHex);
    // should run only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();

      const { hex } = COLOR_THEMES[isDark ? 1 : 0];

      if (!hex) {
        return;
      }

      setTheme(hex);
    },
    [isDark, setTheme]
  );

  return (
    <FormControl
      display="flex"
      width="52px"
      alignItems="center"
      justifyContent="end"
    >
      <Switch id="mode-switch" isChecked={!isDark} onChange={handleSelect}>
        <Text
          fontSize="8px"
          fontWeight={700}
          top="13px"
          left={isDark ? '26px' : undefined}
          right={!isDark ? '26px' : undefined}
          color="white"
          position="absolute"
        >
          {isDark ? 'Dark' : 'Light'}
        </Text>
      </Switch>
    </FormControl>
  );
};

export default ColorModeSwitch;

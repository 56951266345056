import { getEnvValue } from '../utils';
import type { Feature } from './types';


const defaultTxHash = getEnvValue('NEXT_PUBLIC_GRAPHIQL_TRANSACTION');

const title = 'GraphQL API documentation';

const config: Feature<{ defaultTxHash: string | undefined }> = (() => Object.freeze({
    title,
    isEnabled: true,
    defaultTxHash,
  }))();

export default config;

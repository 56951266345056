import { Box, useColorModeValue, Skeleton, Flex } from '@chakra-ui/react';
import { route } from 'nextjs-routes';
import React from 'react';

import IconExplorer from 'icons/icon_explorer.svg';
import IconExplorerCollapsed from 'icons/icon_explorer_collapsed.svg';

interface Props {
  isCollapsed?: boolean;
  onClick?: (event: React.SyntheticEvent) => void;
}

const NetworkLogo = ({ isCollapsed, onClick: handleClick }: Props) => (
  <Box
    as="a"
    href={route({ pathname: '/' })}
    height={isCollapsed ? '30px' : '36px'}
    display="inline-flex"
    overflow="hidden"
    justifyContent="center"
    alignItems="center"
    onClick={handleClick}
    flexShrink={0}
    px="12px"
    gap="4px"
    aria-label="Link to main page"
  >
    {isCollapsed ? (
      <IconExplorerCollapsed
        height="29px"
        display={isCollapsed ? 'block' : 'none'}
      />
    ) : (
      <IconExplorer height="29px" display={isCollapsed ? 'none' : 'block'} />
    )}
  </Box>
);

export default React.memo(NetworkLogo);

import { useColorMode } from '@chakra-ui/react';
import { jsonRpcProvider } from '@wagmi/core/providers/jsonRpc';
import {
  createWeb3Modal,
  useWeb3ModalTheme,
  defaultWagmiConfig,
} from '@web3modal/wagmi/react';
import React from 'react';
import type { Chain } from 'wagmi';
import { configureChains, WagmiConfig } from 'wagmi';

import config from 'configs/app';
import colors from 'theme/foundations/colors';
import zIndices from 'theme/foundations/zIndices';

const feature = config.features.blockchainInteraction;

const getConfig = () => {
  try {
    if (!feature.isEnabled) {
      throw new Error();
    }

    const currentChain: Chain = {
      id: Number(config.chain.id),
      name: config.chain.name || '',
      network: config.chain.name || '',
      nativeCurrency: {
        decimals: config.chain.currency.decimals,
        name: config.chain.currency.name || '',
        symbol: config.chain.currency.symbol || '',
      },
      rpcUrls: {
        public: {
          http: [config.chain.rpcUrl || ''],
        },
        default: {
          http: [config.chain.rpcUrl || ''],
        },
      },
      blockExplorers: {
        default: {
          name: 'Blockscout',
          url: config.app.baseUrl,
        },
      },
    };

    const { chains } = configureChains(
      [currentChain],
      [
        jsonRpcProvider({
          rpc: () => ({
            http: config.chain.rpcUrl || '',
          }),
        }),
      ]
    );

    const wagmiConfig = defaultWagmiConfig({
      chains,
      projectId: feature.walletConnect.projectId,
      enableCoinbase: false,
      enableEIP6963: true,
      enableInjected: false,
      enableWalletConnect: false,
    });

    createWeb3Modal({
      wagmiConfig,
      projectId: feature.walletConnect.projectId,
      chains,
      themeVariables: {
        '--w3m-font-family': `Pretendard`,
        '--w3m-accent': colors.blue[600],
        '--w3m-border-radius-master': '2px',
        '--w3m-z-index': zIndices.modal,
      },
    });

    return { wagmiConfig };
  } catch (error) {
    return {};
  }
};

const { wagmiConfig } = getConfig();

interface Props {
  children: React.ReactNode;
  fallback?: JSX.Element | (() => JSX.Element);
}

const Fallback = ({ children, fallback }: Props) =>
  typeof fallback === 'function' ? fallback() : fallback || <>{children}</>; // eslint-disable-line react/jsx-no-useless-fragment
const Provider = ({ children, fallback }: Props) => {
  const { colorMode } = useColorMode();
  const { setThemeMode } = useWeb3ModalTheme();

  React.useEffect(() => {
    setThemeMode(colorMode);
  }, [colorMode, setThemeMode]);

  // not really necessary, but we have to make typescript happy
  if (!wagmiConfig || !feature.isEnabled) {
    return <Fallback fallback={fallback}>{children}</Fallback>;
  }

  return <WagmiConfig config={wagmiConfig}>{children}</WagmiConfig>;
};

const Web3ModalProvider =
  wagmiConfig && feature.isEnabled ? Provider : Fallback;

export default Web3ModalProvider;

import {
  Box,
  Flex,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';
import IconSvg from 'ui/shared/IconSvg';
import NavigationMobile from 'ui/snippets/navigation/NavigationMobile';
import NetworkLogo from 'ui/snippets/networkMenu/NetworkLogo';
import NetworkMenuButton from 'ui/snippets/networkMenu/NetworkMenuButton';
import NetworkMenuContentMobile from 'ui/snippets/networkMenu/NetworkMenuContentMobile';
import useNetworkMenu from 'ui/snippets/networkMenu/useNetworkMenu';

interface Props {
  isMarketplaceAppPage?: boolean;
}

const Burger = ({ isMarketplaceAppPage }: Props) => {
  const iconColor = useColorModeValue('#1a1a1a', 'white');
  const bgColor = useColorModeValue('#FBF9F9', '#1a1a1a');
  const color = useColorModeValue('#1A1A1A99', '#8F959E');
  const selectedBgColor = useColorModeValue('#9E9E9E1F', '#FFFFFF14');
  const { isOpen, onOpen: handleOpen, onClose: handleClose } = useDisclosure();
  const networkMenu = useNetworkMenu();

  const handleNetworkMenuButtonClick = React.useCallback(() => {
    networkMenu.handleToggle();
  }, [networkMenu]);

  const handleNetworkLogoClick = React.useCallback(
    (event: React.SyntheticEvent) => {
      networkMenu.isOpen && event.preventDefault();
      networkMenu.handleClose();
    },
    [networkMenu]
  );

  return (
    <>
      <Box onClick={handleOpen} cursor="pointer">
        <IconSvg
          name="burger"
          boxSize={6}
          display="block"
          color={iconColor}
          aria-label="Menu button"
        />
      </Box>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={handleClose}
        autoFocus={false}
      >
        <DrawerOverlay />
        <DrawerContent maxWidth="260px" backgroundColor={bgColor} color={color}>
          <DrawerBody p={6} display="flex" flexDirection="column">
            {config.chain.isTestnet && (
              <Flex
                bgColor="#ffffff14"
                color="#FF474C"
                p="4px"
                borderRadius="2px"
                fontSize="12px"
                lineHeight="12px"
                width="fit-content"
              >
                Testnet
              </Flex>
            )}
            <Flex alignItems="center" justifyContent="space-between">
              <NetworkLogo onClick={handleNetworkLogoClick} />
              <NetworkMenuButton
                isMobile
                isActive={networkMenu.isOpen}
                onClick={handleNetworkMenuButtonClick}
              />
            </Flex>
            {networkMenu.isOpen ? (
              <NetworkMenuContentMobile
                tabs={networkMenu.availableTabs}
                items={networkMenu.data}
              />
            ) : (
              <NavigationMobile
                onNavLinkClick={handleClose}
                isMarketplaceAppPage={isMarketplaceAppPage}
              />
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Burger;

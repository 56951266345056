import {
  InputGroup,
  Input,
  InputLeftElement,
  chakra,
  useColorModeValue,
  forwardRef,
  InputRightElement,
} from '@chakra-ui/react';
import throttle from 'lodash/throttle';
import { useRouter } from 'next/router';
import React from 'react';
import type { ChangeEvent, FormEvent, FocusEvent } from 'react';

import { useScrollDirection } from 'lib/contexts/scrollDirection';
import useIsMobile from 'lib/hooks/useIsMobile';
import ClearButton from 'ui/shared/ClearButton';
import IconSvg from 'ui/shared/IconSvg';

interface Props {
  onChange: (value: string) => void;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  handleBlur?: (event: FocusEvent<HTMLFormElement>) => void;
  onFocus?: () => void;
  onHide?: () => void;
  onClear: () => void;
  isHomepage?: boolean;
  value: string;
}

const SearchBarInput = (
  {
    onChange,
    onSubmit: handleSubmit,
    isHomepage,
    onFocus: handleFocus,
    handleBlur,
    onHide,
    onClear: handleClear,
    value,
  }: Props,
  ref: React.ForwardedRef<HTMLFormElement>
) => {
  const router = useRouter();
  const innerRef = React.useRef<HTMLFormElement>(null);
  React.useImperativeHandle(ref, () => innerRef.current as HTMLFormElement, []);
  const [isSticky, setIsSticky] = React.useState(false);
  const scrollDirection = useScrollDirection();
  const isMobile = useIsMobile();

  const handleScroll = React.useCallback(() => {
    const TOP_BAR_HEIGHT = 56;
    if (!isHomepage) {
      if (window.scrollY >= TOP_BAR_HEIGHT) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }
    const clientRect = isMobile && innerRef?.current?.getBoundingClientRect();
    if (clientRect && clientRect.y < TOP_BAR_HEIGHT) {
      onHide?.();
    }
  }, [isMobile, onHide, isHomepage]);

  const handleChange = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  React.useEffect(() => {
    if (!isMobile) {
      return;
    }
    const throttledHandleScroll = throttle(handleScroll, 300);

    window.addEventListener('scroll', throttledHandleScroll);

    return () => {
      window.removeEventListener('scroll', throttledHandleScroll);
    };
  }, [isMobile, handleScroll]);

  const inputBgColor = 'white';
  const bgColor = useColorModeValue('#fbf9f9', '#1a1a1a');
  const transformMobile =
    scrollDirection !== 'down' ? 'translateY(0)' : 'translateY(-100%)';
  const borderColorPage = useColorModeValue('#1A1A1A14', 'transparent');

  return (
    <chakra.form
      ref={innerRef}
      noValidate
      onSubmit={handleSubmit}
      onBlur={handleBlur}
      onFocus={handleFocus}
      w="100%"
      borderRadius="4px"
      position={{ base: isHomepage ? 'static' : 'absolute', lg: 'static' }}
      top={{ base: isHomepage ? 0 : '57px', lg: 0 }}
      left="0"
      zIndex={{ base: isHomepage ? 'auto' : '-1', lg: 'auto' }}
      paddingY={isMobile && !isHomepage ? '10px' : 0}
      paddingX={isMobile && !isHomepage ? '16px' : 0}
      boxShadow={scrollDirection !== 'down' && isSticky ? 'md' : 'none'}
      transform={{ base: isHomepage ? 'none' : transformMobile, lg: 'none' }}
      transitionProperty="transform,box-shadow,background-color,color,border-color"
      transitionDuration="normal"
      transitionTimingFunction="ease"
      backgroundColor={bgColor}
      visibility={
        isMobile && !isHomepage
          ? scrollDirection !== 'down'
            ? 'visible'
            : 'hidden'
          : 'visible'
      }
    >
      <InputGroup height="48px">
        <InputLeftElement
          w={{ base: isHomepage ? 6 : 4, lg: 6 }}
          ml={{ base: isHomepage ? '12px' : '12px', lg: '12px' }}
          h="100%"
        >
          <IconSvg
            name="search"
            boxSize={{ base: isHomepage ? '24px' : '24px', lg: '24px' }}
            color="#1A1A1A"
          />
        </InputLeftElement>
        <Input
          borderColor={borderColorPage}
          height="48px"
          borderRadius="4px"
          backgroundColor={inputBgColor}
          pl={{ base: isHomepage ? '44px' : '44px', lg: '44px' }}
          sx={{
            '@media screen and (max-width: 999px)': {
              paddingLeft: isHomepage ? '44px' : '44px',
              paddingY: '12px',
              paddingRight: '12px',
            },
            '@media screen and (min-width: 1001px)': {
              paddingY: '12px',
              paddingRight: '12px',
            },
          }}
          placeholder={
            isMobile
              ? 'Search by Address / ... '
              : 'Search by Address / Txn hash / Block / Token... '
          }
          onChange={handleChange}
          border={isHomepage ? 'none' : `1px solid ${borderColorPage}`}
          color="#1A1A1A"
          _placeholder={{ color: '#1a1a1a99' }}
          value={value}
        />
        {value && (
          <InputRightElement height="24px" width="24px" top="12px" right={2}>
            <ClearButton onClick={handleClear} />
          </InputRightElement>
        )}
      </InputGroup>
    </chakra.form>
  );
};

export default React.memo(forwardRef(SearchBarInput));

import { useDisclosure } from '@chakra-ui/react';
import React from 'react';

import { featuredNetworks } from 'configs/featured_networks';
import { NETWORK_GROUPS } from 'types/networks';


export default function useNetworkMenu() {
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure();

  const data = featuredNetworks;

  return React.useMemo(() => ({
    isOpen,
    handleClose: onClose,
    onOpen,
    handleToggle: onToggle,
    data,
    availableTabs: NETWORK_GROUPS.filter((tab) => data?.some(({ group }) => group === tab)),
  }), [ isOpen, onClose, onOpen, onToggle, data ]);
}

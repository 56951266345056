import { useColorModeValue } from '@chakra-ui/react';
import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';

const variantSolid = defineStyle((props) => {
  const bg = mode('#1a1a1a', '#748bff')(props);
  const color = 'white';
  const activeBg = useColorModeValue('#1a1a1a', '#748bff');

  return {
    bg,
    color,
    _disabled: {
      opacity: 0.2,
    },
    _hover: {
      bg,
    },
    _active: { bg: activeBg },
    fontWeight: 600,
    fontSize: '16px',
  };
});

const variantOutline = defineStyle((props) => {
  const { colorScheme: c } = props;

  const isGrayTheme = c === 'gray' || c === 'gray-dark';

  const color = isGrayTheme
    ? mode('blackAlpha.800', 'whiteAlpha.800')(props)
    : useColorModeValue('#1a1a1a', '#748bff');
  const borderColor = isGrayTheme
    ? mode('${c}.600', '${c}.300')(props)
    : useColorModeValue('#1a1a1a', '#748bff');
  const activeBg = mode('#1a1a1a', '#749BFF')(props);
  const activeColor = (() => {
    if (c === 'gray') {
      return mode('blue.600', 'gray.50')(props);
    }
    if (c === 'gray-dark') {
      return mode('blue.600', 'gray.50')(props);
    }
    if (c === 'blue') {
      return mode('blue.600', 'gray.50')(props);
    }
    return useColorModeValue('#1a1a1a', '#748bff');
  })();
  const activeBorderColor = mode('#1a1a1a', '#749BFF')(props);

  return {
    color,
    fontWeight: props.fontWeight || 700,
    borderWidth: props.borderWidth || '1px',
    borderStyle: 'solid',
    borderColor,
    bg: 'transparent',
    _hover: {
      color: activeBorderColor,
      borderColor: activeBorderColor,
      bg: 'transparent',
      _active: {
        bg: props.isActive ? activeBg : 'transparent',
        borderColor: props.isActive ? activeBg : activeBorderColor,
        color: props.isActive ? activeColor : activeBorderColor,
        p: {
          color: 'white',
        },
      },
      _disabled: {
        color,
        borderColor,
      },
      p: {
        color: 'white',
      },
    },
    _disabled: {
      opacity: 0.2,
    },
    _active: {
      bg: activeBg,
      borderColor: activeBg,
      color: activeColor,
      _disabled: {
        color,
        borderColor,
      },
      p: {
        color: activeColor,
      },
    },
  };
});

const variantFilter = defineStyle((props) => {
  const { colorScheme: c } = props;
  const color = useColorModeValue('#1A1A1A', '#ffffff');
  const borderColor = useColorModeValue('#1A1A1A66', '#ffffff66');

  return {
    color,
    fontWeight: props.fontWeight || 500,
    fontSize: props.fontSize || '16px',
    paddingX: '4px',
    paddingY: '2px',
    borderWidth: props.borderWidth || '1px',
    borderStyle: 'solid',
    borderColor,
    bg: 'transparent',
    _disabled: {
      opacity: 0.2,
    },
    rightIcon: {
      ml: '0px',
    },
  };
});

const variantSimple = defineStyle((props) => {
  const { colorScheme: c } = props;
  // eslint-disable-next-line no-underscore-dangle
  const color = (() => {
    if (c === 'white') {
      return 'white';
    }
    return '#8F959E';
  })();
  const hoverColor = (() => {
    if (c === 'white') {
      return 'white';
    }
    return '#8F959E';
  })();

  return {
    color,
    _hover: {
      color: hoverColor,
    },
  };
});

const variantWhiteGray = defineStyle((props) => ({
  color: 'white',
  borderColor: '#eeeeee66',
  _hover: {
    bgColor: 'white',
  },
}));

const variantGhost = defineStyle((props) => {
  const { colorScheme: c } = props;
  const color = mode('#8f959e', '#1A1A1A99')(props);

  return {
    bg: 'transparent',
    color,
    _active: {
      bg: 'transparent',
      color,
    },
    _hover: {
      bg: 'transparent',
      color,
      _active: {
        bg: 'transparent',
        color,
      },
    },
  };
});

const variantSubtle = defineStyle((props) => {
  const { colorScheme: c } = props;

  if (c === 'gray') {
    return {
      bg: mode('blackAlpha.200', 'whiteAlpha.200')(props),
      color: mode('blackAlpha.800', 'whiteAlpha.800')(props),
      _hover: {
        color: 'link_hovered',
        _disabled: {
          color: mode('blackAlpha.800', 'whiteAlpha.800')(props),
          bg: mode('blackAlpha.200', 'whiteAlpha.200')(props),
        },
      },
    };
  } else if (c === 'blue') {
    return {
      bg: mode('blackAlpha.200', 'whiteAlpha.200')(props),
      color: mode('blackAlpha.800', 'whiteAlpha.800')(props),
      _hover: {
        color: 'link',
        _disabled: {
          color: mode('blackAlpha.800', 'whiteAlpha.800')(props),
          bg: mode('blackAlpha.200', 'whiteAlpha.200')(props),
        },
      },
    };
  } else if (c === 'white') {
    return {
      bg: mode('#eeeeee66', '#eeeeee66')(props),
      color: mode('#ffffff', '#ffffff')(props),
    };
  }
  return {
    bg: `${c}.100`,
    color: `${c}.600`,
    _hover: {
      color: 'link_hovered',
    },
  };
});

const variantPrevNext = defineStyle((props) => ({
  bg: mode('#1a1a1a', '#ffffff')(props),
  color: mode('#ffffff', '#1a1a1a')(props),
  borderRadius: '4px',
}));

const variants = {
  solid: variantSolid,
  outline: variantOutline,
  simple: variantSimple,
  ghost: variantGhost,
  subtle: variantSubtle,
  prevNext: variantPrevNext,
  whiteGray: variantWhiteGray,
  filter: variantFilter,
};

const baseStyle = defineStyle({
  fontWeight: 600,
  borderRadius: '4px',
  overflow: 'hidden',
  _focusVisible: {
    boxShadow: { base: 'none', lg: 'outline' },
  },
});

const sizes = {
  lg: defineStyle({
    h: 12,
    minW: 'unset',
    fontSize: 'lg',
    px: 6,
  }),
  md: defineStyle({
    h: 10,
    minW: 'unset',
    fontSize: 'md',
    px: 4,
  }),
  sm: defineStyle({
    h: 8,
    minW: 'unset',
    fontSize: 'sm',
    px: 3,
  }),
  xs: defineStyle({
    h: 6,
    minW: 'unset',
    fontSize: 'xs',
    px: 2,
  }),
};

const Button = defineStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    variant: 'solid',
    size: 'md',
    colorScheme: 'blue',
  },
});

export default Button;

import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import Image from 'next/image';
import NextLink from 'next/link';
import React from 'react';

import highlightText from 'lib/highlightText';
import type { MarketplaceAppOverview } from 'types/client/marketplace';

import IconSvg from 'ui/shared/IconSvg';

import SearchBarSuggestItemLink from './SearchBarSuggestItemLink';

interface Props {
  data: MarketplaceAppOverview;
  isMobile: boolean | undefined;
  searchTerm: string;
  onClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

const SearchBarSuggestApp = ({
  data,
  isMobile,
  searchTerm,
  onClick: handleClick,
}: Props) => {
  const color = useColorModeValue('#1a1a1a', '#ffffff');

  const logo = (
    <Box boxSize="20px">
      <Image
        src={useColorModeValue(data.logo, data.logoDarkMode || data.logo)}
        alt={`${data.title} app icon`}
      />
    </Box>
  );

  const content = (() => {
    if (isMobile) {
      return (
        <>
          <Flex alignItems="center">
            {logo}
            <Text
              fontWeight={700}
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              ml={2}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: highlightText(data.title, searchTerm),
                }}
              />
            </Text>
            {data.external && (
              <IconSvg
                name="arrows/north-east"
                boxSize={4}
                color="#8F959E"
                verticalAlign="middle"
                flexShrink={0}
              />
            )}
          </Flex>
          <Text
            overflow="hidden"
            textOverflow="ellipsis"
            sx={{
              display: '-webkit-box',
              '-webkit-box-orient': 'vertical',
              '-webkit-line-clamp': '3',
            }}
          >
            {data.description}
          </Text>
        </>
      );
    }
    return (
      <Flex gap={2} alignItems="center">
        {logo}
        <Text
          fontWeight={700}
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          w="200px"
          flexShrink={0}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: highlightText(data.title, searchTerm),
            }}
          />
        </Text>
        <Text
          color={color}
          overflow="hidden"
          fontWeight={500}
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          flexGrow={1}
        >
          {data.description}
        </Text>
        {data.external && (
          <IconSvg
            name="arrows/north-east"
            boxSize={4}
            verticalAlign="middle"
            color="#8F959E"
            flexShrink={0}
          />
        )}
      </Flex>
    );
  })();

  if (data.external) {
    return (
      <SearchBarSuggestItemLink
        onClick={handleClick}
        href={data.url}
        target="_blank"
      >
        {content}
      </SearchBarSuggestItemLink>
    );
  }

  return (
    <NextLink
      href={{ pathname: '/apps/[id]', query: { id: data.id } }}
      passHref
      legacyBehavior
    >
      <SearchBarSuggestItemLink onClick={handleClick}>
        {content}
      </SearchBarSuggestItemLink>
    </NextLink>
  );
};

export default React.memo(SearchBarSuggestApp);

import { menuAnatomy as parts } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  cssVar,
  defineStyle,
} from '@chakra-ui/styled-system';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const $bg = cssVar('menu-bg');
const $shadow = cssVar('menu-shadow');

const baseStyleList = defineStyle({
  [$bg.variable]: '#fff',
  [$shadow.variable]: 'shadows.2xl',
  _dark: {
    [$bg.variable]: '#282828',
    [$shadow.variable]: 'shadows.dark-lg',
  },
  padding: '8px',
  borderWidth: '0',
  bg: $bg.reference,
  boxShadow: $shadow.reference,
  borderRadius: '4px',
});

const baseStyleItem = defineStyle({
  _focus: {
    [$bg.variable]: '#1a1a1a33',
    _dark: {
      [$bg.variable]: '#1A1A1A',
    },
  },
  _checked: {
    [$bg.variable]: '#1a1a1a33',
    _dark: {
      [$bg.variable]: '#ffffff14',
    },
  },
  _hover: {
    [$bg.variable]: '#1a1a1a33',
    _dark: {
      [$bg.variable]: '#1A1A1A',
    },
  },
  padding: '8px 12px',
  borderRadius: '4px',
  bg: $bg.reference,
  fontWeight: 500,
  marginBottom: '12px',
  _last: {
    marginBottom: 0,
  },
});

const opacityStyleList = defineStyle({
  [$bg.variable]: '#fff',
  [$shadow.variable]: 'shadows.2xl',
  _dark: {
    [$bg.variable]: '#282828',
    [$shadow.variable]: 'shadows.dark-lg',
  },
  borderWidth: '0',
  bg: $bg.reference,
  boxShadow: $shadow.reference,
});

const opacityStyleItem = defineStyle({
  _focus: {
    [$bg.variable]: '#1A1A1A',
    _dark: {
      [$bg.variable]: '#1A1A1A',
    },
  },
  _hover: {
    [$bg.variable]: '#1A1A1A',
    _dark: {
      [$bg.variable]: '#1A1A1A',
    },
  },
  bg: $bg.reference,
});

const miniStyleList = defineStyle({
  [$bg.variable]: '#fff',
  [$shadow.variable]: 'shadows.2xl',
  _dark: {
    [$bg.variable]: '#282828',
    [$shadow.variable]: 'shadows.dark-lg',
  },
  borderWidth: '0',
  bg: $bg.reference,
  boxShadow: $shadow.reference,
  borderRadius: '2px',
});

const miniStyleItem = defineStyle({
  _focus: {
    [$bg.variable]: '#1A1A1A',
    _dark: {
      [$bg.variable]: '#1A1A1A',
    },
  },
  _hover: {
    [$bg.variable]: '#1A1A1A',
    _dark: {
      [$bg.variable]: '#1A1A1A',
    },
  },
  bg: $bg.reference,
  borderRadius: '2px',
});

const blueStyle = definePartsStyle({
  list: opacityStyleList,
  item: opacityStyleItem,
});

const opacityStyle = definePartsStyle({
  list: opacityStyleList,
  item: opacityStyleItem,
});

const baseStyle = definePartsStyle({
  list: baseStyleList,
  item: baseStyleItem,
});

const miniStyle = definePartsStyle({
  list: miniStyleList,
  item: miniStyleItem,
});

const variants = {
  opacity: opacityStyle,
  blue: blueStyle,
  mini: miniStyle,
};

const Menu = defineMultiStyleConfig({
  baseStyle,
  variants,
});

export default Menu;

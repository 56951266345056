
import type { Feature } from './types';

// config file will be downloaded at run-time and saved in the public folder
const configUrl = "";
const submitFormUrl = "";

const title = 'Marketplace';

const config: Feature<{ configUrl: string; submitFormUrl: string }> = (() => 
  // if (
  //   chain.rpcUrl &&
  //   configUrl &&
  //   submitFormUrl
  // ) {
  //   return Object.freeze({
  //     title,
  //     isEnabled: true,
  //     configUrl,
  //     submitFormUrl,
  //   });
  // }

   Object.freeze({
    title,
    isEnabled: true,
      configUrl,
      submitFormUrl,
  })
)();

export default config;

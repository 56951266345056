import { Box } from '@chakra-ui/react';
import localFont from 'next/font/local';
import React from 'react';

const pretendard = localFont({
  src: '../../../../public/static/fonts/PretendardVariable.woff2',
  display: 'swap',
  weight: '45 920',
  variable: '--font-pretendard',
});

interface Props {
  children: React.ReactNode;
}

const Container = ({ children }: Props) => (
  <Box
    minWidth={{ base: '100vw', lg: 'fit-content' }}
    className={pretendard.className}
  >
    {children}
  </Box>
);

export default React.memo(Container);

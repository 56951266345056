import { FeaturedNetwork } from '../types/networks';

const isTestnet = process.env.NEXT_PUBLIC_IS_TESTNET === 'true';

export const featuredNetworks: Array<FeaturedNetwork> = [
  {
    title: 'Bifrost',
    url: 'https://explorer.mainnet.bifrostnetwork.com/',
    group: 'Mainnets',
    isActive: !isTestnet,
    icon: 'icon_bifrost',
  },
  {
    title: 'Bifrost',
    url: 'https://explorer.testnet.bifrostnetwork.com/',
    group: 'Testnets',
    isActive: isTestnet,
    icon: 'icon_bifrost_testnet',
  },
];

export default featuredNetworks;

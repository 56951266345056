import { Heading } from '@chakra-ui/react';
import React from 'react';

interface Props {
  title: string;
}

const AppErrorTitle = ({ title }: Props) => (
  <Heading mt={8} size="2xl">
    {title}
  </Heading>
);

export default AppErrorTitle;

import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

import type { FeaturedNetwork } from 'types/networks';

import IconSvg from 'ui/shared/IconSvg';

import useColors from './useColors';

interface Props extends FeaturedNetwork {
  isActive?: boolean;
  isMobile?: boolean;
}

const NetworkMenuLink = ({
  title,
  icon,
  isActive,
  isMobile,
  url,
  group,
}: Props) => {
  const colors = useColors();
  const networkTagBgColor = useColorModeValue('#1a1a1a14', '#ffffff14');

  return (
    <Box as="li" listStyleType="none">
      <Flex
        as="a"
        href={url}
        alignItems="center"
        cursor="pointer"
        pointerEvents={isActive ? 'none' : 'initial'}
        borderRadius="base"
        color={colors.text.active}
        opacity={isActive ? 1 : 0.6}
        bgColor={colors.bg.default}
      >
        <IconSvg name={icon} boxSize={5} />
        <Text
          marginLeft={2}
          marginRight={3}
          fontWeight="500"
          color="inherit"
          fontSize={isMobile ? 'sm' : 'md'}
          lineHeight={isMobile ? '20px' : '24px'}
        >
          {title}
        </Text>
        <Flex
          bgColor={networkTagBgColor}
          color="#FF474C"
          p="4px"
          borderRadius="2px"
          fontSize="12px"
          lineHeight="12px"
        >
          {group === 'Mainnets' ? 'Mainnet' : 'Testnet'}
        </Flex>
        {isActive && <IconSvg name="check" boxSize="24px" marginLeft="auto" />}
      </Flex>
    </Box>
  );
};

export default React.memo(NetworkMenuLink);

import { ChakraProvider as ChakraProviderDefault } from '@chakra-ui/react';
import type { ChakraProviderProps } from '@chakra-ui/react';
import React from 'react';

interface Props extends ChakraProviderProps {}

export function ChakraProvider({ theme, children }: Props) {
  return (
    <ChakraProviderDefault theme={theme}>{children}</ChakraProviderDefault>
  );
}

import { alertAnatomy as parts } from '@chakra-ui/anatomy';
import type { StyleFunctionProps } from '@chakra-ui/styled-system';
import {
  createMultiStyleConfigHelpers,
  cssVar,
} from '@chakra-ui/styled-system';
import { mode, transparentize } from '@chakra-ui/theme-tools';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const $fg = cssVar('alert-fg');
const $bg = cssVar('alert-bg');

function getBg(props: StyleFunctionProps) {
  const { theme, colorScheme: c } = props;
  const darkBg = transparentize(`${c}.200`, 0.16)(theme);
  return {
    light: `colors.${c}.${c === 'red' ? '50' : '100'}`,
    dark: darkBg,
  };
}

const baseStyle = definePartsStyle({
  container: {
    bg: $bg.reference,
    borderRadius: '4px',
    px: '10px',
    py: '10px',
  },
  title: {
    fontWeight: 'bold',
    lineHeight: '6',
    marginEnd: '2',
  },
  description: {
    lineHeight: '6',
  },
  icon: {
    color: $fg.reference,
    flexShrink: 0,
    marginEnd: '3',
    w: '5',
    h: '6',
  },
  spinner: {
    color: $fg.reference,
    flexShrink: 0,
    marginEnd: '3',
    w: '5',
    h: '5',
  },
});

const variantSubtle = definePartsStyle((props) => {
  const { colorScheme } = props;
  const bg = mode('#1A1A1A1F', '#eeeeee66')(props);

  return {
    container: {
      [$fg.variable]:
        colorScheme === 'gray'
          ? 'colors.blackAlpha.800'
          : `colors.${colorScheme}.500`,
      [$bg.variable]: bg,
      _dark: {
        [$fg.variable]:
          colorScheme === 'gray'
            ? 'colors.whiteAlpha.800'
            : `colors.${colorScheme}.200`,
        [$bg.variable]: bg,
      },
    },
  };
});

const variantSolid = definePartsStyle((props) => {
  const { colorScheme: c } = props;
  return {
    container: {
      [$fg.variable]: `colors.white`,
      [$bg.variable]: `#eeeeee66`,
      _dark: {
        [$fg.variable]: `colors.gray.900`,
        [$bg.variable]: `#eeeeee66`,
      },
      color: $fg.reference,
    },
  };
});

const variants = {
  subtle: variantSubtle,
  solid: variantSolid,
};

const Alert = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: 'subtle',
    colorScheme: 'blue',
  },
});

export default Alert;

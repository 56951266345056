const semanticTokens = {
  colors: {
    divider: {
      default: '#1A1A1A14',
      _dark: '#FFFFFF14',
    },
    text: {
      default: 'blackAlpha.800',
      _dark: 'whiteAlpha.800',
    },
    text_secondary: {
      default: '#8F959E',
      _dark: '#8F959E',
    },
    link: {
      default: '#749BFF',
    },
    link_hovered: {
      default: '#FF474C',
    },
    error: {
      default: 'red.400',
      _dark: 'red.300',
    },
  },
  shadows: {
    action_bar:
      '0 4px 4px -4px rgb(0 0 0 / 10%), 0 2px 4px -4px rgb(0 0 0 / 6%)',
  },
};

export default semanticTokens;
